import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import status from "../assets/json/status.json"
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import Folder from "../assets/images/Folder.svg"
import Document from "../components/Icons/Document"
import {useNavigate} from "react-router-dom";
import {addCertificate, resetAddCertificate} from "../actions/CertificateActions";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";
import {decoded} from "../components/PrivateRoute";
import {getTeacherAssociates} from "../actions/ConfigActions";

const AddCertificate = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    let file_doc
    const certificateNameRef = useRef();
    const certificateTypeRef = useRef();
    const notesTypeRef = useRef();
    const [teacher, setTeacher] = useState([])
    const [imgFile, uploading] = useState()
    const [accessLevel, setAccessLevel] = useState(false)
    const hiddenFileInput = useRef(null);
    const [alertModal, setAlertModal] = useState({
        display: false,
        message: ""
    })

    const [fieldIsValid, setFieldIsValid] = useState({
        isCertificateNameValid: false,
        isTeacherValid: false,
        isCertificateTypeValid: false,
        isStatusValid: false,
        isToDateValid: false,
        isFromDateValid: false,
        isNotesValid: false,
        isFileValid: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        certificateNameErrorMessage: "",
        teacherErrorMessage: "",
        certificateTypeErrorMessage: "",
        statusErrorMessage: "",
        toDateErrorMessage: "",
        fromDateErrorMessage: "",
        notesErrorMessage: "",
        fileErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        dataCertificateName: null,
        dataTeacher: null,
        dataCertificateType: null,
        dataStatus: null,
        dataToDate: null,
        dataFromDate: null,
        dataNote: null,
        dataFile: null,
    });

    const addCertificateDetails = useSelector(state => state.addCertificate)
    const {error, loading, certificateInfo} = addCertificateDetails

    useEffect(() => {
        dispatch(getTeacherAssociates())
        setAccessLevel(decoded.RoleAccessLevels)
        if (decoded.RoleAccessLevels == 0) {
            setFieldIsValid({
                ...fieldIsValid,
                isTeacherValid: true,
                isCertificateTypeValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                teacherErrorMessage: "",
                certificateTypeErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataTeacher: 0,
                dataCertificateType: "None"
            })
        }
    }, []);

    useEffect(() => {
        if (addCertificateDetails.certificate === true) {
            dispatch(resetAddCertificate())
            history('/certifications',{state:{success: "1"}})
        } if (addCertificateDetails.error) {
            setAlertModal({
                display: true,
                message: "Certificate couldn't be added"
            })
        }
    }, [addCertificateDetails]);

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const logoClick = event => {
        hiddenFileInput.current.click();
    };
    const imageFileHandler = (e) => {
        if (e.target.files.length !== 0) {
            file_doc = e.target.files[0]
            console.log(file_doc)
            uploading(URL.createObjectURL(e.target.files[0]))
            setFieldIsValid({
                ...fieldIsValid,
                isFileValid: true
            });
            setFieldData({
                ...fieldData,
                dataFile: file_doc
            })
        }
        console.log(file_doc)
    }

    const notesKeyPress = (event) => {
        event.preventDefault();
        console.log(notesTypeRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(notesTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                notesErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                notesErrorMessage: ""
            });

            setFieldData({
                ...fieldData,
                dataNote: notesTypeRef.current.value
            })
        }
    }
    const certificateNameKeyPress = (event) => {
        event.preventDefault();
        console.log(certificateNameRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(certificateNameRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateNameValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                certificateNameErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateNameValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                certificateNameErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataCertificateName: certificateNameRef.current.value
            })
        }
    }
    const certificateTypeKeyPress = (event) => {
        event.preventDefault();
        console.log(certificateTypeRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(certificateTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateTypeValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                certificateTypeErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateTypeValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                certificateTypeErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataCertificateType: certificateTypeRef.current.value
            })
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('Title', certificateNameRef.current.value);
        formData.append('Notes', notesTypeRef.current.value);
        formData.append('IsActive', fieldData.dataStatus);
        formData.append('Status', fieldData.dataStatus);
        // formData.append('AssociateId', 1);
        formData.append('AssociateId', fieldData.dataTeacher);
        formData.append('Type', fieldData.dataCertificateType);
        formData.append('ValidFromDate', formatDate(fieldData.dataFromDate));
        if (fieldData.dataToDate != null) {
            formData.append('ValidToDate', formatDate(fieldData.dataToDate));
        }
        formData.append('FormFile', fieldData.dataFile);

        dispatch(addCertificate(formData));
    }

    const openLink = () => {
        history('/certifications')
    }
    const onReset = (e) => {
        e.preventDefault()
        notesTypeRef.current.value = ""
        certificateNameRef.current.value = ""
        setFieldData({
            ...fieldData,
            dataCertificateName: null,
            dataTeacher: null,
            dataCertificateType: null,
            dataStatus: null,
            dataToDate: null,
            dataFromDate: null,
            dataNote: null,
            dataFile: null,
        })
        setFieldIsValid({
            ...fieldIsValid,
            isCertificateNameValid: false,
            isTeacherValid: false,
            isCertificateTypeValid: false,
            isStatusValid: false,
            isToDateValid: false,
            isFromDateValid: false,
            isNotesValid: false,
            isFileValid: false,
        })
    }
    const teacherData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isTeacherValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            teacherErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const certificateData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isCertificateTypeValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            certificateTypeErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataCertificateType: data.id
        })
    }
    const signatureStatusData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            statusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataStatus: data.id
        })
    }
    const fromData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isFromDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            fromDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataFromDate: data
        })
    }
    const toData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isToDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            toDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataToDate: data
        })
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }
    const pageName = 'certifications';
    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type="error" result={closeAlert}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="certificate_add" title="Certification List" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        {accessLevel != 0 && <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                              className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Teacher/Associate<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={teacher} result={teacherData}
                                                                        value={fieldData.dataTeacher}
                                                                        className={!fieldIsValid.isTeacherValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Teacher/Associate"/>
                                                </div>
                                            </div>
                                        </Col>}
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={accessLevel != 0 ? "padding-right-unset" : "padding-left-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing">
                                                    Certificate Name<span className="asterics">*</span></label>
                                                <input ref={certificateNameRef} onChange={certificateNameKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isCertificateNameValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Certificate Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={accessLevel != 0 ? "padding-left-unset":"padding-right-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Valid From<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select Valid From"
                                                                            value={fieldData.dataFromDate}
                                                                            result={fromData}
                                                                            className={!fieldIsValid.isFromDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={accessLevel != 0 ? "padding-right-unset" : "padding-left-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Valid To<span className="asterics"></span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select Valid To"
                                                                            value={fieldData.dataToDate}
                                                                            result={toData}
                                                                            className={!fieldIsValid.isToDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={accessLevel != 0 ? "padding-left-unset":"padding-right-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing">
                                                    Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={status}
                                                                        value={fieldData.dataStatus}
                                                                        result={signatureStatusData}
                                                                        className={!fieldIsValid.isStatusValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Status"/>
                                                </div>
                                            </div>
                                        </Col>
                                        {accessLevel != 0 && <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                              className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Certificate Type<span className="asterics">*</span></label>
                                                <input ref={certificateTypeRef} onChange={certificateTypeKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isCertificateTypeValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Certificate Type"
                                                       id="document_title"/>
                                            </div>
                                        </Col>}
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-right-unset padding-left-unset">
                                            <div className={!fieldIsValid.isNotesValid ? "document-form-textarea-error"
                                                : "document-form-textarea"}
                                            >
                                                <span className="text-label form-spacing">
                                                    Note (Optional)</span>
                                                <textarea ref={notesTypeRef} onChange={notesKeyPress}
                                                          className="textarea-input-error textarea-spacing"
                                                          placeholder="Add Notes" rows="4"
                                                          id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    {fieldData.dataFile == null && <Row className="background-1 file-box">
                                        <Row className="background-1" onClick={logoClick}>
                                            <img src={Folder} alt="Extensis HR" className="icon-upload"/>
                                            <span className="span-upload">Select file to upload</span>
                                        </Row>
                                        <input type="file"
                                               id='image'
                                               accept='.doc, .docx, .pdf'
                                               onChange={imageFileHandler}
                                               ref={hiddenFileInput}
                                               style={{display: 'none'}}
                                        />
                                    </Row>}
                                    {fieldData.dataFile != null && <Row className="background-1 file-box-small">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                            <Row className="background-1" onClick={logoClick}>
                                                <Col lg={{span: 1}} className="doc-icon">
                                                    <Document className="sidebar-icon"
                                                              color="var(--primary-100)"/>
                                                </Col>
                                                <Col lg={{span: 11}} className="doc-name-outer">
                                                    <Row className="background-1">
                                                        {fieldData.dataFile != null ? fieldData.dataFile.name : ''}
                                                    </Row>
                                                    <Row className="background-1 doc-size">
                                                        File
                                                        size: {fieldData.dataFile != null ? Math.round(fieldData.dataFile.size / 102400).toFixed(2) : ''} MB
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <input type="file"
                                                   id='image'
                                                   accept='.doc, .docx, .pdf'
                                                   onChange={imageFileHandler}
                                                   ref={hiddenFileInput}
                                                   style={{display: 'none'}}
                                            />
                                        </Col>
                                    </Row>}
                                    {(
                                            !fieldIsValid.isCertificateNameValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isCertificateTypeValid
                                            || !fieldIsValid.isStatusValid
                                            // || !fieldIsValid.isToDateValid
                                            || !fieldIsValid.isFromDateValid
                                            // || !fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Add
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isCertificateNameValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isCertificateTypeValid
                                            && fieldIsValid.isStatusValid
                                            // && fieldIsValid.isToDateValid
                                            && fieldIsValid.isFromDateValid
                                            // && fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={submitHandler}
                                                >
                                                    Add
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default AddCertificate;