import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import {
    createVerificationReducer, getUserAccessLevelReducer, getUserMfaDetailsReducer,
    loginReducer, verifyOtpReducer
} from '../reducers/LoginReducers'
import {
    addDocumentReducer, deleteDocumentReducer,
    editDocumentReducer,
    getAllDocumentReducer,
    getDocumentByIdReducer
} from "../reducers/DocumentReducers";
import {
    addSchoolYearReducer, deleteSchoolYearReducer,
    editSchoolYearReducer, getAllSchoolYearExistReducer,
    getAllSchoolYearReducer,
    getSchoolYearByIdReducer, uploadCalenderEventsReducer
} from "../reducers/CalendarReducers";
import {
    addEventReducer,
    deleteEventReducer, editEventReducer,
    getAllEventsReducer,
    getEventByDateReducer, getEventBySingleDateReducer, getUpcomingSchoolEventsReducer
} from "../reducers/EventReducers";
import {
    getAllDepartmentReducer, getAllLettersTemplateListReducer,
    getCategoryReducer, getDashboardDataReducer, getDynamicReducer, getEmployeeBaReducer, getEmployeePayrollReducer,
    getEventTypeReducer, getRedirectUrlReducer,
    getSignatureStatusReducer, getStatusReducer, getTaskTypeReducer,
    getTeacherAssociateReducer
} from "../reducers/ConfigReducers";
import {
    addCertificateReducer, deleteCertificateReducer,
    editCertificateReducer, exportAllCertificateReducer, getAllCertificateReducer,
    getCertificateByIdReducer, uploadBulCertificatesReducer, uploadBulKCertificatesReducer
} from "../reducers/CertificateReducers";
import {
    addProcessReducer,
    addProcessTemplateReducer, cancelAllTaskReducer, completeAllTaskReducer,
    editProcessTemplateReducer,
    editTaskByIdReducer,
    getAllProcessesReducer,
    getAllProcessTemplateReducer,
    getProcessByIdReducer,
    getProcessTemplateByIdReducer,
    getTaskProcessByIdReducer, getUpcomingTaskReducer, markTaskAsCompleterReducer, sendTaskNudgeReducer
} from "../reducers/ProcessReducers";
import {
    addLettersTemplateReducer,
    editLettersTemplateReducer, getAllLettersNoticesReducer, getAllLettersTemplateReducer, getLettersNoticesByIdReducer,
    getLettersTemplateByIdReducer, getTemplateColumnReducer, sendLettersNoticesReducer
} from "../reducers/LetterNoticesReducers";
import {
    addSuperAdminReducer, deleteSuperAdminReducer,
    editSuperAdminReducer,
    getAllSuperAdminReducer,
    getSuperAdminByIdReducer
} from "../reducers/SuperAdminReducers";
import {
    addClientReducer, deleteClientByIdReducer,
    getAllClientReducer, getClientByIdReducer,
    getClientUserByIdReducer,
    getClientUsersReducer, updateClientUserReducer
} from "../reducers/ClientManagementReducers";
import {
    addTrustedAdvisorReducer,
    deleteTrustedAdvisorByIdReducer, getAllTrustedAdvisorReducer,
    getTrustedAdvisorByIdReducer
} from "../reducers/TrustedAdvisorReducers";
import {getAnalyticsReducer} from "../reducers/AnalyticsReducers";

const reducer = combineReducers({
    login: loginReducer,
    createVerification: createVerificationReducer,
    verifyOtp: verifyOtpReducer,
    getUserAccessLevel: getUserAccessLevelReducer,
    getUserMfaDetails: getUserMfaDetailsReducer,

    addDocument: addDocumentReducer,
    editDocument: editDocumentReducer,
    getDocument: getDocumentByIdReducer,
    getAllDocuments: getAllDocumentReducer,
    deleteDocument: deleteDocumentReducer,

    addSchoolYear: addSchoolYearReducer,
    editSchoolYear: editSchoolYearReducer,
    deleteSchoolYear: deleteSchoolYearReducer,
    getSchoolYearById: getSchoolYearByIdReducer,
    getAllSchoolYear: getAllSchoolYearReducer,
    getAllSchoolYearExist: getAllSchoolYearExistReducer,
    uploadCalenderEvents: uploadCalenderEventsReducer,

    addEvent: addEventReducer,
    editEvent: editEventReducer,
    getAllEvents: getAllEventsReducer,
    getEventsByDate: getEventByDateReducer,
    deleteEvent: deleteEventReducer,
    getEventBySingleDate: getEventBySingleDateReducer,
    getUpcomingSchoolEvents: getUpcomingSchoolEventsReducer,

    getDashboardData: getDashboardDataReducer,
    getEmployeePayroll: getEmployeePayrollReducer,
    getEmployeeBa: getEmployeeBaReducer,
    getTeacherAssociate: getTeacherAssociateReducer,
    getAllDepartment: getAllDepartmentReducer,
    getCategory: getCategoryReducer,
    getSignatureStatus: getSignatureStatusReducer,
    getEventType: getEventTypeReducer,
    getTaskType: getTaskTypeReducer,
    getStatus: getStatusReducer,
    getDynamic: getDynamicReducer,
    getAllLettersTemplateList: getAllLettersTemplateListReducer,
    getRedirectUrl: getRedirectUrlReducer,

    addCertificate: addCertificateReducer,
    editCertificate: editCertificateReducer,
    getCertificateById: getCertificateByIdReducer,
    getAllCertificate: getAllCertificateReducer,
    deleteCertificate: deleteCertificateReducer,
    exportAllCertificate: exportAllCertificateReducer,
    uploadBulKCertificates: uploadBulKCertificatesReducer,

    addProcessTemplate: addProcessTemplateReducer,
    editProcessTemplate: editProcessTemplateReducer,
    getProcessTemplateById: getProcessTemplateByIdReducer,
    getAllProcessTemplate: getAllProcessTemplateReducer,

    addProcess: addProcessReducer,
    getTaskProcessById: getTaskProcessByIdReducer,
    getAllProcesses: getAllProcessesReducer,
    getProcessById: getProcessByIdReducer,
    editTaskById: editTaskByIdReducer,
    completeAllTask: completeAllTaskReducer,
    cancelAllTask: cancelAllTaskReducer,
    getUpcomingTask: getUpcomingTaskReducer,
    sendTaskNudge: sendTaskNudgeReducer,
    markTaskAsComplete: markTaskAsCompleterReducer,

    addLettersTemplate: addLettersTemplateReducer,
    editLettersTemplate: editLettersTemplateReducer,
    getLettersTemplateById: getLettersTemplateByIdReducer,
    getAllLettersTemplate: getAllLettersTemplateReducer,
    getTemplateColumn: getTemplateColumnReducer,
    sendLettersNotices: sendLettersNoticesReducer,
    getLettersNoticesById: getLettersNoticesByIdReducer,
    getAllLettersNotices: getAllLettersNoticesReducer,

    addSuperAdmin:addSuperAdminReducer,
    editSuperAdmin:editSuperAdminReducer,
    getSuperAdminById:getSuperAdminByIdReducer,
    getAllSuperAdmin:getAllSuperAdminReducer,
    deleteSuperAdmin:deleteSuperAdminReducer,

    addClient:addClientReducer,
    getClientById:getClientByIdReducer,
    getAllClient:getAllClientReducer,
    getClientUsers:getClientUsersReducer,
    getClientUserById:getClientUserByIdReducer,
    updateClientUser:updateClientUserReducer,
    deleteClientById:deleteClientByIdReducer,

    addTrustedAdvisor:addTrustedAdvisorReducer,
    getTrustedAdvisorById:getTrustedAdvisorByIdReducer,
    getAllTrustedAdvisor:getAllTrustedAdvisorReducer,
    deleteTrustedAdvisorById:deleteTrustedAdvisorByIdReducer,

    getAnalytics:getAnalyticsReducer,
})

const userInfoFromStorage = localStorage.getItem('access_token') ?
    JSON.parse(localStorage.getItem('access_token')) : null

const initialState = {
    login: {userInfo: userInfoFromStorage},
    getUserAccessLevel: {userInfo: userInfoFromStorage},
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store