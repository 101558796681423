import api from './api';
import {
    LOGIN_SUCCESS,
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_RESET,

    CREATE_VERIFICATION_REQUEST,
    CREATE_VERIFICATION_SUCCESS,
    CREATE_VERIFICATION_FAIL,
    CREATE_VERIFICATION_RESET,

    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAIL,
    VERIFY_OTP_RESET,

    GET_USER_ACCESS_LEVEL_REQUEST,
    GET_USER_ACCESS_LEVEL_SUCCESS,
    GET_USER_ACCESS_LEVEL_FAIL,
    GET_USER_ACCESS_LEVEL_RESET,

    GET_USER_MFA_DETAILS_REQUEST,
    GET_USER_MFA_DETAILS_SUCCESS,
    GET_USER_MFA_DETAILS_FAIL,
    GET_USER_MFA_DETAILS_RESET,

    LOGOUT
} from "../constants/LoginConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
            }
        }

        const {data} = await api.post(
            `${url}/Token`,
            {'UserName': email, 'Password': password},
            config)

        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })
        console.log(data)
        if (data.success == true) {
            localStorage.setItem('access_token', JSON.stringify(data.data.token))
            localStorage.setItem('userName', data.data.userName)
            localStorage.setItem('employeeId', data.data.employeeId)
            localStorage.setItem("mfa_verify", "false")
            localStorage.setItem('clients', JSON.stringify(data.data.clients))
        } else {
            console.log("ERROR: ", data.status)
            dispatch({
                type: LOGIN_FAIL,
                payload: data.message,
            })
        }

    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const createVerification = (VerificationType, Mobnumber, Email) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_VERIFICATION_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
            }
        }
        const {data} = await api.post(
            `${url}/Token/CreateVerification`,
            {
                // "UserId": localStorage.getItem("employeeId"),
                "VerificationType": VerificationType,
                "Mobnumber": Mobnumber,
                "Email": Email
            },
            config)

        dispatch({
            type: CREATE_VERIFICATION_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CREATE_VERIFICATION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const verifyOtp = (Code, VerificationType, Email, Mobnumber) => async (dispatch) => {
    try {
        dispatch({
            type: VERIFY_OTP_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
            }
        }
        const {data} = await api.post(
            `${url}/Token/VerifyToken`,
            {
                "VerificationType": VerificationType,
                "Email": Email,
                "Mobnumber": Mobnumber,
                "Code": Code,
                // "UserId": localStorage.getItem("employeeId")
            },
            config)

        dispatch({
            type: VERIFY_OTP_SUCCESS,
            // payload: data.success
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: VERIFY_OTP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getUserAccessLevel = (ClientId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_USER_ACCESS_LEVEL_REQUEST
        })

        const config = {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('access_token'))}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }
        const {data} = await api.post(`${url}/Token/GetUserAccessLevel?ClientId=${ClientId}`, {}, config)

        if (data.success === true) {
            dispatch({
                type: GET_USER_ACCESS_LEVEL_SUCCESS,
                payload: data.success,
                result: data.data
            })
        }

        if (data.success === false) {
            dispatch({
                type: GET_USER_ACCESS_LEVEL_FAIL,
                payload: data.success,
            })
        }
    } catch (error) {
        dispatch({
            type: GET_USER_ACCESS_LEVEL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getUserMfaDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_USER_MFA_DETAILS_REQUEST
        })

        const config = {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('access_token'))}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }
        const {data} = await api.get(`${url}/Token/GetUserMFADetail`, config)

        if (data.success === true) {
            dispatch({
                type: GET_USER_MFA_DETAILS_SUCCESS,
                payload: data.data
            })
        }

        if (data.success === false) {
            dispatch({
                type: GET_USER_MFA_DETAILS_FAIL,
                payload: data.success,
            })
        }
    } catch (error) {
        dispatch({
            type: GET_USER_MFA_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetLogin = () => async (dispatch, getState) => {
    dispatch({
        type: LOGIN_RESET,
    })
};
export const resetVerification = () => async (dispatch, getState) => {
    dispatch({
        type: CREATE_VERIFICATION_RESET,
    })
};
export const resetVerifyOtp = () => async (dispatch, getState) => {
    dispatch({
        type: VERIFY_OTP_RESET,
    })
};
export const resetGetUserAccessLevel = () => async (dispatch, getState) => {
    dispatch({
        type: GET_USER_ACCESS_LEVEL_RESET,
    })
};
export const resetGetUserMfaDetails = () => async (dispatch, getState) => {
    dispatch({
        type: GET_USER_MFA_DETAILS_RESET,
    })
};
export const logout = () => (dispatch) => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('userName')
    localStorage.removeItem('employeeId')
    localStorage.removeItem('personalEmail')
    localStorage.removeItem('workEmail')
    localStorage.removeItem('mobilePhone')
    localStorage.removeItem('isSuperAdmin')
    localStorage.removeItem('clients')
    localStorage.removeItem('roleAccessLevels')
    localStorage.removeItem('isPayrollEnable')
    localStorage.removeItem('isLettersEnable')
    localStorage.removeItem('isDocumentsEnable')
    localStorage.removeItem('isProcessesEnable')
    localStorage.removeItem('IsTrainingEnable')
    localStorage.removeItem('client_name')
    localStorage.removeItem('client_name')
    localStorage.removeItem('client_id')
    localStorage.removeItem('letters-notices')
    localStorage.removeItem('processes')
    localStorage.removeItem('mfa_verify')
    localStorage.removeItem('code_verify')
    // window.location.href = '/sign-in'
    dispatch({type: LOGOUT})
}

