import {
    ADD_CERTIFICATE_SUCCESS,
    ADD_CERTIFICATE_REQUEST,
    ADD_CERTIFICATE_FAIL,
    ADD_CERTIFICATE_RESET,

    EDIT_CERTIFICATE_SUCCESS,
    EDIT_CERTIFICATE_REQUEST,
    EDIT_CERTIFICATE_FAIL,
    EDIT_CERTIFICATE_RESET,

    GET_CERTIFICATE_BY_ID_SUCCESS,
    GET_CERTIFICATE_BY_ID_REQUEST,
    GET_CERTIFICATE_BY_ID_FAIL,
    GET_CERTIFICATE_BY_ID_RESET,

    GET_ALL_CERTIFICATES_SUCCESS,
    GET_ALL_CERTIFICATES_REQUEST,
    GET_ALL_CERTIFICATES_FAIL,
    GET_ALL_CERTIFICATES_RESET,

    DELETE_CERTIFICATES_REQUEST,
    DELETE_CERTIFICATES_SUCCESS,
    DELETE_CERTIFICATES_FAIL,
    DELETE_CERTIFICATES_RESET,

    EXPORT_CERTIFICATES_REQUEST,
    EXPORT_CERTIFICATES_SUCCESS,
    EXPORT_CERTIFICATES_FAIL,
    EXPORT_CERTIFICATES_RESET,

    IMPORT_CERTIFICATES_REQUEST,
    IMPORT_CERTIFICATES_SUCCESS,
    IMPORT_CERTIFICATES_FAIL,
    IMPORT_CERTIFICATES_RESET
} from "../constants/CertificateConstants"
import {
    UPLOAD_CALENDAR_EVENTS_FAIL,
    UPLOAD_CALENDAR_EVENTS_REQUEST, UPLOAD_CALENDAR_EVENTS_RESET,
    UPLOAD_CALENDAR_EVENTS_SUCCESS
} from "../constants/CalendarConstants";

const initialState = {};
export const addCertificateReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CERTIFICATE_REQUEST:
            return { loading: true }

        case ADD_CERTIFICATE_SUCCESS:
            return {
                loading: false,
                certificate: action.payload,
            }

        case ADD_CERTIFICATE_FAIL:
            return { loading: false, error: action.payload }

        case ADD_CERTIFICATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const editCertificateReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_CERTIFICATE_REQUEST:
            return { loadingEdit: true }

        case EDIT_CERTIFICATE_SUCCESS:
            return {
                loadingEdit: false,
                certificate: action.payload,
            }

        case EDIT_CERTIFICATE_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_CERTIFICATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getCertificateByIdReducer = (state = {certificate_data: []}, action) => {
    switch (action.type) {
        case GET_CERTIFICATE_BY_ID_REQUEST:
            return { loading: true }

        case GET_CERTIFICATE_BY_ID_SUCCESS:
            return {
                loading: false,
                certificate_data: action.payload,
            }

        case GET_CERTIFICATE_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_CERTIFICATE_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteCertificateReducer = (state = {certificate_delete: []}, action) => {
    switch (action.type) {
        case DELETE_CERTIFICATES_REQUEST:
            return { loadingDelete: true }

        case DELETE_CERTIFICATES_SUCCESS:
            return {
                loadingDelete: false,
                certificate_delete: action.payload,
            }

        case DELETE_CERTIFICATES_FAIL:
            return { loadingDelete: false, error: action.payload }

        case DELETE_CERTIFICATES_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllCertificateReducer = (state = {certificate_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_CERTIFICATES_REQUEST:
            return { loading: true }

        case GET_ALL_CERTIFICATES_SUCCESS:
            return {
                loading: false,
                certificate_list: action.payload,
                records: action.records
            }

        case GET_ALL_CERTIFICATES_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_CERTIFICATES_RESET:
            return initialState;

        default:
            return state
    }
}
export const exportAllCertificateReducer = (state = {certificate_list: []}, action) => {
    switch (action.type) {
        case EXPORT_CERTIFICATES_REQUEST:
            return { loadingCertificateExport: true }

        case EXPORT_CERTIFICATES_SUCCESS:
            return {
                loadingCertificateExport: false,
                certificate_list: action.payload,
            }

        case EXPORT_CERTIFICATES_FAIL:
            return { loadingCertificateExport: false, error: action.payload }

        case EXPORT_CERTIFICATES_RESET:
            return initialState;

        default:
            return state
    }
}
export const uploadBulKCertificatesReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPORT_CERTIFICATES_REQUEST:
            return { loadingUpload: true }

        case IMPORT_CERTIFICATES_SUCCESS:
            return {
                loadingUpload: false,
                upload_certificates: action.payload,
            }

        case IMPORT_CERTIFICATES_FAIL:
            return { loadingUpload: false, errorUpload: action.payload, errorSuccess: action.success }

        case IMPORT_CERTIFICATES_RESET:
            return initialState;

        default:
            return state
    }
}