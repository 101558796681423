export const ADD_CERTIFICATE_REQUEST = 'ADD_CERTIFICATE_REQUEST'
export const ADD_CERTIFICATE_SUCCESS = 'ADD_CERTIFICATE_SUCCESS'
export const ADD_CERTIFICATE_FAIL = 'ADD_CERTIFICATE_FAIL'
export const ADD_CERTIFICATE_RESET = 'ADD_CERTIFICATE_RESET'
export const GET_CERTIFICATE_BY_ID_REQUEST = 'GET_CERTIFICATE_BY_ID_REQUEST'
export const GET_CERTIFICATE_BY_ID_SUCCESS = 'GET_CERTIFICATE_BY_ID_SUCCESS'
export const GET_CERTIFICATE_BY_ID_FAIL = 'GET_CERTIFICATE_BY_ID_FAIL'
export const GET_CERTIFICATE_BY_ID_RESET = 'GET_CERTIFICATE_BY_ID_RESET'
export const EDIT_CERTIFICATE_REQUEST = 'EDIT_CERTIFICATE_REQUEST'
export const EDIT_CERTIFICATE_SUCCESS = 'EDIT_CERTIFICATE_SUCCESS'
export const EDIT_CERTIFICATE_FAIL = 'EDIT_CERTIFICATE_FAIL'
export const EDIT_CERTIFICATE_RESET = 'EDIT_CERTIFICATE_RESET'
export const GET_ALL_CERTIFICATES_REQUEST = 'GET_ALL_CERTIFICATES_REQUEST'
export const GET_ALL_CERTIFICATES_SUCCESS = 'GET_ALL_CERTIFICATES_SUCCESS'
export const GET_ALL_CERTIFICATES_FAIL = 'GET_ALL_CERTIFICATES_FAIL'
export const GET_ALL_CERTIFICATES_RESET = 'GET_ALL_CERTIFICATES_RESET'
export const DELETE_CERTIFICATES_REQUEST = 'DELETE_CERTIFICATES_REQUEST'
export const DELETE_CERTIFICATES_SUCCESS = 'DELETE_CERTIFICATES_SUCCESS'
export const DELETE_CERTIFICATES_FAIL = 'DELETE_CERTIFICATES_FAIL'
export const DELETE_CERTIFICATES_RESET = 'DELETE_CERTIFICATES_RESET'
export const EXPORT_CERTIFICATES_REQUEST = 'EXPORT_CERTIFICATES_REQUEST'
export const EXPORT_CERTIFICATES_SUCCESS = 'EXPORT_CERTIFICATES_SUCCESS'
export const EXPORT_CERTIFICATES_FAIL = 'EXPORT_CERTIFICATES_FAIL'
export const EXPORT_CERTIFICATES_RESET = 'EXPORT_CERTIFICATES_RESET'
export const IMPORT_CERTIFICATES_REQUEST = 'IMPORT_CERTIFICATES_REQUEST'
export const IMPORT_CERTIFICATES_SUCCESS = 'IMPORT_CERTIFICATES_SUCCESS'
export const IMPORT_CERTIFICATES_FAIL = 'IMPORT_CERTIFICATES_FAIL'
export const IMPORT_CERTIFICATES_RESET = 'IMPORT_CERTIFICATES_RESET'